import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import SubjectForm from '../commons/subjectForm';


function CompleteSubjectInfo() {

    return (
        <Stack gap={2}>
            <p className="noto-sans subtitles-size text-secundario fw-bold text-center mt-3">
                Completa tus Datos
            </p>
            <p>
                Hola! Para encontrar los experimentos disponibles para usted, necesitamos que especifique algunos aspectos personales.
            </p>
            <Container>
                <Row>
                    <SubjectForm 
                        buttonText={'Completar Registro'}
                        urlSuccess={'/loading'}
                        />
                </Row>
            </Container>
        </Stack>
    );
}

export default CompleteSubjectInfo;
