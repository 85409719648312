import TopMenu from "./TopMenu";
import Footer from "./footer"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function Main({children, className}){
    
    return (
        <Container className={'content-wrapper ' + className} fluid>
            <Row>
                <Col>
                    <TopMenu/>  
                </Col> 
            </Row>
            <Row >
                {children}
            </Row>
            <Row>
                <Footer/>
            </Row>
            
        </Container>
    );
} 

export default Main;