import axios from '../api/axios';
import useAuth from './useAuth';


const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();
    
    const refresh = async () => {
        const response = await axios.post(
            'api/token/refresh/', 
            {"refresh": auth.refreshToken},
        );
        console.log('RESPONSE USEREFRESH', response);
        setAuth(prev => {
            console.log(JSON.stringify(prev));
            console.log(response.data.accessToken);
            return { ...prev, accessToken: response.data.accessToken }
        });
        
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;