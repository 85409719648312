import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'
import { Stack } from "react-bootstrap";


function Footer(){
    return(
       <Container className={'bg-secundario p-3'} fluid>
            <Row className={'mx-0'}>
                <Stack direction="horizontal" gap={3}>
                    <Image src={"/liaa-logo.png"} className={'footer-image'}/>
                    <p className={"ms-auto"}>Cualquier duda podés comunicarte por mail a: 
                        <a href="mailto:somosdatapruebas@gmail.com">somosdatapruebas@gmail.com</a>
                    </p>
                </Stack>
            </Row>
        </Container> 
    );
}

export default Footer;