import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SessionsCard from "../commons/SessionsCard";
import { selectAllExperiments } from '../redux/experimentsSlice';
import { selectAllSessions } from '../redux/sessionsSlice';

import { fetchSessions } from "../redux/sessionsThunk";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SessionsList() {
    const sessions = useSelector(selectAllSessions)[0]; // Obtener sesiones
    const exp = useSelector(selectAllExperiments)[0]; // Obtener experimentos
    const dispatch = useDispatch();


    console.log('SESSIONS', sessions.pending_runs);

    const [cards, setCards] = useState([]);

    useEffect(() => {
        dispatch(fetchSessions()); // Carga las sesiones al montar el componente
    }, [dispatch]);

    

    useEffect(() => {
        const allCards = [];
    
        // Verifica que sessions y exp estén definidos
        if (sessions && exp && Array.isArray(exp)) {
            // Procesar citas si existen
            if (Array.isArray(sessions.appointments)) {
                const appointmentExperimentIds = sessions.appointments.map(appointment => appointment.experiment);
                const appointmentExperiments = exp.filter(e => appointmentExperimentIds.includes(e.id));
    
                // Filtrar solo las sesiones que tengan experimentos válidos
                const validAppointments = sessions.appointments.filter(appointment =>
                    appointmentExperiments.some(e => e.id === appointment.experiment)
                );
    
                // Agregar datos de cita a los experimentos relacionados
                const appointmentCards = validAppointments.map(appointment => {
                    const experiment = appointmentExperiments.find(e => e.id === appointment.experiment);
                    return {
                        ...experiment,
                        appointment_date: appointment.date || null, // Manejo seguro de fecha
                        appointment_time: appointment.time || null, // Manejo seguro de hora
                        type: experiment?.is_scheduled ? 'Presencial' : 'Online',
                    };
                });
    
                allCards.push(...appointmentCards);
            }
    
            // Procesar ejecuciones pendientes si existen
            if (Array.isArray(sessions.pending_runs)) {
                const pendingExperimentIds = sessions.pending_runs.map(pending => pending.experiment);
                const pendingExperiments = exp.filter(e => pendingExperimentIds.includes(e.id));
    
                // Filtrar solo las ejecuciones que tengan experimentos válidos
                const validPendingRuns = sessions.pending_runs.filter(pending =>
                    pendingExperiments.some(e => e.id === pending.experiment)
                );
    
                // Agregar tipo "Pendiente" a los experimentos relacionados
                const pendingCards = validPendingRuns.map(pending => {
                    const experiment = pendingExperiments.find(e => e.id === pending.experiment);
                    return {
                        ...experiment,
                        type: 'Online',
                    };
                });
    
                allCards.push(...pendingCards);
            }
        }
    
        // Actualizar estado con las tarjetas generadas
        if (JSON.stringify(allCards) !== JSON.stringify(cards)) {
            setCards(allCards);
        }
    }, [sessions, exp, cards]);
    
    if (sessions.length <= 0){
        return null;
    }

    return (
        <Row className={'bg-neutral mt-3 px-4'}>
            <p className={'noto-sans super-subtitles-size text-secundario fw-bolder p-3'}>
                Tu Actividad
            </p>
            
            {cards.length > 0 ? cards.map((card, index) => (   
                <Col sm={6} md={4} lg={3} className={'d-flex align-items-stretch mb-3'}>   
                        <SessionsCard 
                            type={card.type}
                            id={card.id}
                            image={card.image}
                            title={card.name}
                            estimated_time={'x minutos'}
                            text={card.description}
                            is_scheduled={card.is_scheduled}
                            date_created={card.creation_datetime}
                            owner={card.owner}
                            url={card.url}
                            appointment_date={card.appointment_date || null} // Fecha para citas
                            appointment_time={card.appointment_time || null} // Hora para citas
                            restrictions={card.device_restriction}
                            requisites={card.requisites}
                        />
                    </Col>     
                )) : (
                    <p className="text-muted">No hay actividades disponibles.</p>
                )
            }
             
        </Row>
    );
}

export default SessionsList;
