import { useState } from "react";
import useAuth from "../hooks/useAuth";
import axios from '../api/axios';

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function ChangePasswordButton() {
    const { auth } = useAuth();
    const [emailAdress, setEmailAdress] = useState("");
    const [message, setMessage] = useState(null); // Estado para el mensaje
    const [messageType, setMessageType] = useState(null); // Estado para el tipo de mensaje (éxito o error)

    const controller = new AbortController();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/dj-rest-auth/password/reset/', {
                email: emailAdress
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                }
            });
            setMessage("Se ha enviado el correo con las instrucciones para restablecer tu contraseña."); // Mensaje de éxito
            setMessageType("success");
        } catch (err) {
            setMessage(err.response?.data?.detail || "Ocurrió un error al enviar el correo."); // Mensaje de error
            setMessageType("danger");
        }
    };

    return (
        <Container fluid>
            {message && (
                <Alert variant={messageType} className="my-3">
                    {message}
                </Alert>
            )}
            <Form onSubmit={handleSubmit}>
                <p>Ingresa yu email y te enviaremos los pasos a seguir a tu casilla</p>
                <Form.Group className="mb-3 d-flex align-items-center" controlId="formBasicEmail">
                    <Form.Control
                        type="email"
                        placeholder="Ingresa tu email"
                        className="me-2"
                        value={emailAdress}
                        onChange={(e) => setEmailAdress(e.target.value)}
                    />
                    <Button variant="primary" type="submit">
                        Enviar
                    </Button>
                </Form.Group>
            </Form>
        </Container>
    );
}

export default ChangePasswordButton;
