import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function BackButton({text, link}){

    let history = useNavigate();

    let navigateTo = link === "" ? (-1) : link; 

    return(
        <Button onClick={() => history(navigateTo)} variant={'link'} className={'main-link my-0 py-0'}>
            <p className={'my-0'}><small><FontAwesomeIcon icon={faChevronLeft} size={'xs'} className={'px-2'}/>{text}</small></p>
        </Button>
    );
}

export default BackButton;