import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import axios from '../api/axios';
import LoadingData from '../data/loadData';
import ChangePasswordButton from './ChangePasswordButton';

import Card from 'react-bootstrap/Card';
import Button  from "react-bootstrap/Button";
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert'

const LOGIN_URL = '/api/token/';

export function LoginForm(){

    const navigate = useNavigate();
    const location = useLocation();

    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    let navigateTo = location.pathname !== "/" ? "/complete-registration" : "/loading"

    // Estado para el modal
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    // Funciones para manejar el estado del modal
    const handleShowPasswordModal = () => setShowPasswordModal(true);
    const handleClosePasswordModal = () => setShowPasswordModal(false);

    useEffect(() => {
        if (userRef.current) {
            userRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(LOGIN_URL, 
                JSON.stringify({ email: user, password: pwd }), {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': '*',
                },
                withCredentials: false,
            });
            console.log('RESPONSE', response?.data);
            const accessToken = response?.data?.access;
            const refreshToken = response?.data?.refresh;
            setAuth({ user, pwd, accessToken, refreshToken });
            setUser('');
            setPwd('');
            setSuccess(true);
            sessionStorage.setItem('isAuthenticated', true);
            navigate(navigateTo);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            if (errRef.current) {
                errRef.current.focus();
            }
        }
    };


    return(
        <Stack gap={5}>
            <Card className={'m-3 p-3 no-border'}>
                <Card.Title>
                    <p className={'noto-sans subtitles-size text-secundario fw-bold text-center mt-3'}>
                        ¿Ya estás registrado?
                    </p>
                </Card.Title>
                <Card.Subtitle>
                    <p className={'pt-sans subtitles-size text-center pb-3'}>
                        Ingresa a tu cuenta
                    </p>
                </Card.Subtitle>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Control 
                            type="email" 
                            placeholder="Email"
                            onChange={(e) => setUser(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="password" 
                                placeholder="Password"
                                onChange={(e) => setPwd(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <p className={'text-center'}>
                                <span 
                                    className="text-primary" 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={handleShowPasswordModal}>
                                    ¿Olvidaste tu contraseña?
                                </span>
                            </p>
                        </Form.Group>
                        <Button className={'w-100 m-auto texts-size'} variant="primary" type='submit'>
                            Ingresar
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            {/* Modal para cambiar contraseña */}
            <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Resetear Contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChangePasswordButton />
                </Modal.Body>
            </Modal>
        </Stack>
    );
}

const REGISTER_URL = 'api/users/'

export function RegisterForm(){

    const navigate = useNavigate();

    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [switchModalContent, setSwitchModalContent] = useState(false)
    const handleClose = () => {
        setShowRegisterModal(false)
        setErrMsg('')
        setUserEmail('')
        setUserPwd('')
        setMatchPwd('')
    };
    const handleShow = () => setShowRegisterModal(true);

    const [userEmail, setUserEmail] = useState('');
    const [userPwd, setUserPwd] = useState('');
    const [matchPwd, setMatchPwd] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        if (userPwd !== matchPwd){
            setErrMsg('Las contraseñas no coinciden')
        } else {
        const email = userEmail;
        const password = userPwd;
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ username: email, email : email, password: password}),
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Headers': '*' 
                     },
                }
            );
            console.log(response?.data);
            console.log(response?.accessToken);
            console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setUserEmail('');
            setUserPwd('');
            setMatchPwd('');
            setSwitchModalContent(true)
        } catch (err) {
            if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg(err.response.password)
            }
        }}
    }

    let modalContent = 
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title >Ingresá tus datos
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className={'p-3'}>
                            <Form onSubmit={handleSubmit} className={'mx-3 p-3'}>
                            {errMsg !== "" && <Alert key={'err'} variant={'danger'}>{errMsg}</Alert>}
                                <Form.Group className="mb-3">
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Ingresa tu correo electrónico."
                                        onChange={(e) => setUserEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Crea una contraseña segura."
                                        onChange={(e) => setUserPwd(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Volvé a ingresar tu contraseña."
                                        onChange={(e) => setMatchPwd(e.target.value)} />
                                </Form.Group>
                                <Button className={'w-100 m-auto my-3 texts-size'} variant="primary" type='submit' > Ingresar </Button>
                            </Form>
                        </Modal.Body>
                    </>

    let modalCheckMail = <>
                         <Modal.Header closeButton>
                            <Modal.Title > Usuario registrado correctamente </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className={'p-3'}>
                           <p> Por favos Verifique su casilla de mail para confirmar sus datos</p>
                        </Modal.Body>
    
    </>

    return(
        <Stack gap={5}>
            <Card className={'m-3 p-3 no-border'}>
                <Card.Title> 
                    <p className={'noto-sans subtitles-size text-secundario fw-bold text-center mt-3'}>Registrate ahora y empezá a explorar
                    </p>
                </Card.Title>
                {/* <Card.Subtitle>
                    <Image src="\iso_whiteBG.svg" className={'iso-logo-card m-auto d-flex'}/>
                </Card.Subtitle> */}
                <Card.Body>
                    {/* <p className={'pt-sans text-size text-center pb-3 my-3'} >Crea tu cuenta para generar tu perfil </p> */}
                    <Button className={'w-100 mt-5 texts-size'} variant="primary" onClick={handleShow}> Registrarse</Button>
                </Card.Body>
            </Card>
            <Modal show={showRegisterModal} size={'lg'} onHide={handleClose}>
                {switchModalContent === true ? modalCheckMail :  modalContent}
            </Modal>
        </Stack>
    )
}

