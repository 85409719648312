import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CardBody, CardHeader, ModalTitle } from "react-bootstrap";
import ScheduleExperiment from "../components/scheduleExperiment";
import Card from "react-bootstrap/Card"
import Image from "react-bootstrap/Image";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { Tooltip, OverlayTrigger } from "react-bootstrap";


import {faHourglass, faLaptop, faMobile, faHeadphones, faMicrophone, faVideo, faVolumeXmark, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SessionCard({type, image, title, estimated_time, text, is_scheduled , date_created, owner, url, id,  appointment_date,  appointment_time, requisites, restrictions}) {
    
    const BASE_URL = 'https://npdev.liaa.dc.uba.ar/'
    const navigate = useNavigate();
    
    let [showModal, setShowModal] = useState(false)

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    console.log('type', type)

    let  RESTRICTIONS=[ 
        { name : 'Escritorio', value: 'E', icon: faLaptop},
        { name : 'Celular', value: 'C', icon: faMobile}
    ]

    let REQUISITES =[ 
        { name : 'Auriculares', value: 'A', icon: faHeadphones},
        { name : 'Microfono', value: 'M', icon: faMicrophone},
        { name : 'Webcam', value: 'W', icon: faVideo},
        { name : 'Ambiente silencioso', value: 'B', icon: faVolumeXmark },
        { name : 'Teclado', value: 'O', icon: faKeyboard},
    ]
    

    const expDetailModal = 
    <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header className="gradient-bg align-items-baseline" closeButton>
            <ModalTitle></ModalTitle>
            <Container>
                <Row className={'m-0 p-0'}> 
                    <Col sm={6}>
                        <p className={'pt-sans fw-bolder super-subtitles-size'}>{title}</p>
                    </Col>
                    <Col>
                        <Image src={ BASE_URL + image} className={'activity-card-image rounded-top'}/>
                    </Col>
                </Row>
            </Container>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row className="w-75 m-auto">
                    <Col>
                        <Table>
                            <thead className={'bg-primario text-white texts-size w-100'}>
                                <tr >
                                    <th>ESPECIFICACIONES</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Autor</td>
                                    <td>{owner}</td>
                                </tr>
                                <tr>
                                    <td>Descripcion</td>
                                    <td>{text}</td>
                                </tr>
                                <tr>
                                    <td>Tiempo Estimado</td>
                                    <td>{estimated_time}</td>
                                </tr>
                                <tr>
                                    <td>Requisitos</td>
                                    <td>{requisites}</td>
                                </tr>
                                <tr>
                                    <td>Restricciones</td>
                                    <td>{restrictions}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container> 
            </Modal.Body>

    </Modal>

    
    return(
        <>
        <Card className={'section-card shadow-sm m-2 no-border p-2 m-0 no-border no-radius-border mw-25'}>
            <CardHeader className={'d-flex align-content-center gradient-bg no-border no-radius-border p-0 '}>
            <Image src={ BASE_URL + image} className={'activity-card-image my-4 py-3 rounded-top'}/>
                <small className={'activity-type-flag text-center text-white fw-bold' + (type === 'Online' ? ' bg-red' : ' bg-green')}> {type}</small>    
            </CardHeader>
            <CardBody className={'d-flex flex-row '}>
                <div>
                    <h4 className={'pt-sans fw-bolder d-table-row'}>{title}</h4>
                    <p><FontAwesomeIcon icon={faHourglass} color="#2DC2E5" size="sm"/><small className={'text-muted px-2 mr-3'}>Tiempo estimado: {estimated_time}</small></p>
                    
                      {/* Sección de Requisitos */}
               
                <div className="d-flex">
                <small>Requisitos:</small>
                    {requisites && requisites.length > 0 ? (
                        REQUISITES.filter(req => requisites.includes(req.value)).map(req => (
                            <OverlayTrigger
                                key={req.value}
                                placement="top" // Posición del tooltip
                                overlay={<Tooltip id={`tooltip-${req.value}`}>{req.name}</Tooltip>}
                            >
                            <FontAwesomeIcon 
                                key={req.value} 
                                icon={req.icon} 
                                title={req.name}
                                size="xs" 
                                className="mx-2 mt-1"
                                color="#2DC2E5" 
                            />
                            </OverlayTrigger>
                        ))
                    ) : (
                        <small className="text-muted mx-2">No hay requisitos.</small>
                    )}
                </div>

                {/* Sección de Restricciones */}
                
                <div className="d-flex">
                <small>Restricciones:</small>
                    {restrictions && restrictions.length > 0 ? (
                        RESTRICTIONS.filter(res => restrictions.includes(res.value)).map(res => (
                            <OverlayTrigger
                                key={res.value}
                                placement="top" // Posición del tooltip
                                overlay={<Tooltip id={`tooltip-${res.value}`}>{res.name}</Tooltip>}
                            >
                            <FontAwesomeIcon 
                                key={res.value} 
                                icon={res.icon} 
                                title={res.name} 
                                className="mx-2 mt-1"
                                size="xs" 
                                color="#2DC2E5"
                            />
                            </OverlayTrigger>
                        ))
                    ) : (
                        <small className="text-muted mx-2">No hay restricciones.</small>
                    )}
                </div>
                    
                    <p className={'text-truncate  d-table-row'}>{text} <br>
                        </br> <a onClick={handleShow} className={'mt-0 pt-0 no-decoration text-secundario cursor'}>Ver mas</a> 
                    </p>
                </div>
            </CardBody>
            <Card.Footer className={'no-border bg-white d-flex flex-column justify-content-center'}>
            { !is_scheduled ?
                <Button variant={'success'} className={'w-100 m-auto'} onClick={() => navigate(url)}>{'Continuar'}</Button> : 
                <div  className={'text-center text-white fw-bold bg-green p-2' }>
                    <small className={'d-block'}> Cita Agendada: </small> 
                    <small> {`${new Date(appointment_date).toLocaleDateString()} ${appointment_time.split(':')[0]}:${appointment_time.split(':')[1]}hs`}</small>    
                </div>
            }
            </Card.Footer>
        </Card>
        {expDetailModal}
        </>
    );
}

export default SessionCard;