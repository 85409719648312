// sessionsThunks.js
import axios from '../api/axios';
import { addSessions } from './sessionsSlice';

export const fetchSessions = () => async (dispatch) => {
    try {
        const response = await axios.get('/api/sessions'); // Cambia el endpoint si es necesario
        dispatch(addSessions(response.data)); // Actualiza el estado con los datos de la API
    } catch (error) {
        console.error('Error fetching sessions:', error);
        // Podrías manejar errores despachando otra acción si fuera necesario
    }
};
