import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import useAuth from "../hooks/useAuth";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import Col from 'react-bootstrap/Col'
import { Nav } from "react-bootstrap";
import { NavDropdown, Modal, Button } from "react-bootstrap";
import { selectAllMe } from "../redux/meSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function TopMenu(){

    const meArray = useSelector(selectAllMe)[0] 
    const me = meArray && meArray.length > 0 ? meArray[0] : null; // Verificar si `meArray` no está vacío
    const navigate = useNavigate();
    const auth = useAuth()

    const RESEARCHER_URL = process.env.IS_REASERCHER_URL;

    const logOut = () => {
        sessionStorage.setItem('isAuthenticated', 'false');
        localStorage.clear();
        navigate('/');
      };

    const location = useLocation();

    // State para manejar el modal
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return(
       <Stack direction="horizontal" gap={3} className={'d-flex justify-content-between p-2 mb-3 bg-secundario fixed-top'}>
        <Col className={'d-flex'}>
            <div className={'w-75  bg-primario logo-bg-transform'}>
                <Image src={"/logo_blueBG.svg"} className={'logo-position'}></Image>
            </div>
        </Col>
        <Col className={'d-flex justify-content-end align-items-center'}>
            <Nav>
                { location.pathname !== "/" ?
                    <NavDropdown 
                    className={'noto-sans super-subtitles-size text-white mb-0'}
                    title={<FontAwesomeIcon icon={faUser}/>}
                    >
                    {me?.is_researcher && ( // Verificar si `me` no es null y tiene la propiedad `is_researcher`
 
                        <NavDropdown.Item >
                            <Link to={RESEARCHER_URL} className="dropdow-item text-decoration-none text-reset"> Administrar experimentos</Link>
                        </NavDropdown.Item>)}
                        <NavDropdown.Item onClick={()=> navigate('/administrar-cuenta')} >Administrar Cuenta</NavDropdown.Item>
                        <NavDropdown.Item onClick={()=> logOut()}>Logout </NavDropdown.Item> 
                </NavDropdown> : <Nav.Link>
                    <p className={'noto-sans super-subtitles-size text-white mb-0'} onClick={handleOpenModal}>¿Quienes somos?</p>
                </Nav.Link>  }
            </Nav>
        </Col>

        {/* Modal */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Laboratorio de Inteligencia Artificial Aplicada
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>El Laboratorio de Inteligencia Artificial Aplicada (LIAA) pertenece al Departamento de Computación, Facultad de Ciencias Exactas y Naturales, Universidad de Buenos Aires y al Instituto de Investigación en Ciencias de la Computación, CONICET- UBA.</p>
                
            <p>El LIAA es un laboratorio interdisciplinario, que combina proyectos de la Lingüística Computacional, Neurociencia Computacional, Procesamiento de Lenguaje Natural, Sistemas de Diálogo, Reconocimiento del Habla y Análisis en Tiempo Real de Señales del Cerebro. Para poder llevar adelante la mayoría de nuestros proyectos de investigación requerimos de datos. Para poder conseguir esos datos, necesitamos que muchas personas resuelvan distintas tareas mientras los grabamos o anotamos cierta información. ¡Ayudanos a conseguir esto! </p>
               
            <p>Si sos investigador y querés sumar tu experimento a nuestro sitio comunicate con nosotros a través del siguiete mail: somosdatapruebas@gmail.com</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
       </Stack>
    );
}

export default TopMenu;
