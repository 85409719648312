import { createSlice } from "@reduxjs/toolkit";



const initialState =[]

export const experimentSlice = createSlice({
    name: 'experiments',
    initialState,
    reducers:{
        addExperiment(state, action){
            console.log("EPERIMENT Action dispatched:", action); // Log del payload recibido
            console.log("EXPERIMENT State before:", state); // Estado antes del cambio
            state.push(action.payload);
            console.log("EXPERIMENT State after:", state); // Estado después del cambio
        }
    }
})

export const selectAllExperiments = (state) => state.experiments;
export const { addExperiment } = experimentSlice.actions;
export default experimentSlice.reducer;