import Main from "../commons/Main";
import CompleteSubjectInfo from "../components/CompleteSubjectInfoForm";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card";
import { CardBody, CardTitle } from "react-bootstrap";


function CompleteRegistration(){

    let completeSubjectInfo =
        <Container  className="fullscreen-wrapper pt-4" fluid >
            <Row className={'mt-4 pt-4 d-flex justify-content-center align-content-center'}>
                    <Card className={'w-50 p-2 text-center'}>
                        <CardBody>
                            <CompleteSubjectInfo/>
                        </CardBody>
                    </Card>
            </Row>
        </Container>

return (
        <Main children={completeSubjectInfo} className={'bg-light'}/>
)

}

export default CompleteRegistration;