
import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import LoadingData from "./data/loadData"
import CompleteRegistration from "./pages/CompleteRegistration";
import VerifyAccount from './commons/verifyMail';
import AdminProfile from "./pages/AdminProfile";
import ResetPassword from "./pages/ResetPassword";

import './App.css';

function App() {

    return (
    <Routes>
        <Route path="/" element={<Login/> }/>
        <Route path="/loading" element={<LoadingData/> } />
        <Route path="/complete-registration" element={<CompleteRegistration/>}/>
        <Route path="/confirmar-email/:key" element={<VerifyAccount />} />
        <Route path="/administrar-cuenta" element={<AdminProfile />} />
        <Route path="/cambiar_contrasenia/confirmar/:uid/:key" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Home/> }/>
    </Routes>
    );
}

export default App;