import { createSlice } from "@reduxjs/toolkit";



const initialState =[]

export const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers:{
        addMe(state, action){
            state.push(action.payload)
            console.log('ME STATE', action.payload)
        }
    }
})

export const selectAllMe = (state) => state.me;
export const { addMe } = meSlice.actions;
export default meSlice.reducer;