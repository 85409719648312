import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import experimentsReducer from  './experimentsSlice'
import subjectReducer from "./subjectSlice";
import messageReducer from "./messageSlice";
import availableExperimentsReducer from "./availableExperimentsSlice";
import sessionsReducer from "./sessionsSlice"
import meReducer from './meSlice'


const rootReducer = combineReducers({
    experiments: experimentsReducer,
    subject: subjectReducer,
    message: messageReducer,
    sessions: sessionsReducer,
    available_experiments: availableExperimentsReducer,
    me: meReducer,
 }
);

const persistConfig = {
    key: 'root',
    storage,
    version: 1
}


const persistedReducer = persistReducer(persistConfig, rootReducer)

const loggerMiddleware = (store) => (next) => (action) => {
    console.log("Middleware - Dispatching:", action.type); // Muestra la acción
    console.log("Middleware - Payload:", action.payload); // Muestra el payload (si existe)
    const result = next(action); // Pasa la acción al siguiente middleware/reducer
    console.log("Middleware - Next State:", store.getState()); // Estado después de la acción
    return result;
};

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(loggerMiddleware), // Incluye el logger
    devTools: process.env.NODE_ENV !== "production",
});


export const persistor =  persistStore(store)
