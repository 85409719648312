
import Main from "../commons/Main";
import Admin from "../components/admin";
import Container from 'react-bootstrap/Container';



function AdminProfile(){
 
    let adminContent =
        <Container fluid>
            <Admin/>
        </Container>

return (
        <Main children={adminContent}/>
)

}

export default AdminProfile;