import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { useDispatch } from "react-redux";

import axios from '../api/axios';
import { getSubject} from "../data/dataFetchers";
import Button  from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { selectAllSubject } from '../redux/subjectSlice';

const SUBJECT_URL = '/api/subjects/';

const GENDER_CHOICES = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Femenino' },
    { value: 'N', label: 'No binarie' },
    { value: 'G', label: 'Género fluido' },
    { value: 'O', label: 'Ninguna de las opciones me identifica' },
    { value: 'D', label: 'Prefiero no decirlo' },
];

const EDUCATIONLEVEL_CHOICES = [
    { value: 'P', label: 'Primaria completa' },
    { value: 'S', label: 'Secundaria completa' },
    { value: 'T', label: 'Terciaria completa' },
    { value: 'U', label: 'Universitaria completa' },
    { value: 'R', label: 'Posgrado completo' },
];

const SubjectForm = ({ buttonText, urlSuccess}) => { 

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    let subject = useSelector(selectAllSubject)[0] || [];
    console.log('SUBJECT', subject)

    const userRef = useRef(null); // Reference for the first input
    const errRef = useRef();

    console.log('auth SF', auth);

    const [gender, setGender] = useState('');
    const [educationLevel, setEducationLevel] = useState('');
    const [nationality, setNationality] = useState('');
    const [birthRegion, setBirthRegion] = useState('');
    const [residenceCountry, setResidenceCountry] = useState('');
    const [residenceRegion, setResidenceRegion] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [allowNotifications, setAllowNotifications] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (subject !== undefined){
            setGender(subject.gender);
            setEducationLevel(subject.level_of_education);
            setNationality(subject.nationality);
            setBirthRegion(subject.birthplace_region);
            setResidenceCountry(subject.residence_country);
            setResidenceRegion(subject.residence_region);
            setBirthDate(subject.birthdate);
            setAllowNotifications(subject.allow_notifications);
        }
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [gender, educationLevel]);

    const controller = new AbortController();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(SUBJECT_URL, {
                gender: gender,
                level_of_education: educationLevel,
                nationality: nationality,
                birthplace_region: birthRegion,
                residence_country: residenceCountry,
                residence_region: residenceRegion,
                birthdate: birthDate,
                allow_notifications: allowNotifications,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                }
            });
            console.log('RESPONSE', response?.data);
            setSuccess(true);
            getSubject(controller, dispatch, axiosPrivate, auth);
            if (urlSuccess) {
                navigate(urlSuccess);
        }
        } catch (err) {
            setErrMsg(err.response ? 'Algo ha fallado' : 'No Server Response');
        }
    };

    return (  
        <Form onSubmit={handleSubmit}>
             <p ref={errRef} className={errMsg ? "errmsg" : "d-none"} aria-live="assertive">
                {errMsg}
            </p>
            <Form.Group className="mb-3 px-3 m-auto">
                <Row>
                    <Col>
                        <Form.Select
                            id="gender"
                            ref={userRef} // Focus on this input
                            onChange={(e) => setGender(e.target.value)}
                            value={gender}
                            required
                            className="mb-3"
                        >
                            <option value="">Seleccione su género</option>
                            {GENDER_CHOICES.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select
                            id="educationLevel"
                            onChange={(e) => setEducationLevel(e.target.value)}
                            value={educationLevel}
                            required
                            className="mb-3"
                        >
                            <option value="">Seleccione su nivel educativo</option>
                            {EDUCATIONLEVEL_CHOICES.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control
                            type="text"
                            placeholder="Nacionalidad"
                            id="nationality"
                            onChange={(e) => setNationality(e.target.value)}
                            value={nationality}
                            required
                            className="mb-3"
                        />
                        <Form.Control
                            type="text"
                            placeholder="Región de Nacimiento"
                            id="birthRegion"
                            onChange={(e) => setBirthRegion(e.target.value)}
                            value={birthRegion}
                            required
                            className="mb-3"
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="País de Residencia"
                            id="residenceCountry"
                            onChange={(e) => setResidenceCountry(e.target.value)}
                            value={residenceCountry}
                            required
                            className="mb-3"
                        />
                        <Form.Control
                            type="text"
                            placeholder="Región de Residencia"
                            id="residenceRegion"
                            onChange={(e) => setResidenceRegion(e.target.value)}
                            value={residenceRegion}
                            required
                            className="mb-3"
                        />
                        <Form.Control
                            type="date"
                            placeholder="Fecha de Nacimiento"
                            id="birthDate"
                            onChange={(e) => setBirthDate(e.target.value)}
                            value={birthDate}
                            required
                            className="mb-3"
                        />
                        <Form.Check
                            inline
                            label="Recibir Notificaciones"
                            name="allow_notifications"
                            type="checkbox"
                            id="allowNotifications"
                            onChange={(e) => setAllowNotifications(e.target.checked)}
                            checked={allowNotifications}
                            className="mb-3"
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Button className="m-auto mx-3" variant="primary" type="submit">
                {buttonText}            
            </Button>
        </Form>         
    );
}

export default SubjectForm;